* {
  display: "flex";
  flex-wrap: "wrap";
  margin: 0;
  padding: 0;
  max-width: 100%;
  max-height: 100%;

  box-sizing: border-box;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 100vw;
  min-height: 100vh;
  max-height: 100%;

  background: #f3f3f3;
  transition: 0.2s;
}
.containerLog {
  max-width: 100%;
  min-height: 640px;
  background: #ffffff;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container.dark {
  background: #15123a;
}
.layout {
  margin: 0;
  padding: 0;
  color: #222;
}
.layout.dark {
  color: #ced8ff;
}

.Gauge {
  background: #c9c9c9;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(220, 228, 245);
  padding: 2px;
  margin: 2px;
  height: 200px;
  border-radius: 20px;
}
.logIn {
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: beige;
  padding: 50px;
  margin: 2px;
  border-radius: 20px;
  border-style: solid;
  border-radius: 20px;
  border-color: rgb(65, 169, 255);
}
.logIn button {
  display: inline-block;
  margin: 10px 10px;
  font-size: 25px;
  padding: 10px 20px;
  background-color: rgb(65, 169, 255);
  cursor: pointer;
  color: rgb(252, 252, 252);
  border-radius: 20px;
  border: #ced8ff;
}
.buttonOne {
  display: inline-block;
  margin: 10px 10px;
  font-size: 15px;
  padding: 10px 20px;

  cursor: pointer;
  color: #15123a;
  border-radius: 20px;
  border: #ced8ff;
}
.TextField1 {
  display: inline-block;
  margin: 10px 10px;
  font-size: 15px;
  padding: 10px 20px;

  cursor: pointer;
  color: #ffffff;
  border-radius: 20px;
  border: #ced8ff;
}
