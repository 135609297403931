.navbar {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #a6b2cf; */
  padding: 0.5em;
  
  transition: 0.2s;
  border-style: solid;
  border-radius: 2px;

  border-color: rgb(65, 169, 255);
}
/* = */
.ul1 {
  flex: 1;
  list-style: none;
  text-align: center;
}
.li1 {
  display: flex;
  align-items: center;
  margin: 5px 5px;
  background-color: rgb(65, 169, 255);
  font-size: 18px;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-around;
  padding: 0;
  border: #ced8ff;
  border-radius: 20px;
  width: 120px;
  height: 40px;
  /* max-height: 100%; */
}

/* ==================================================================== */
.nav-links {
  flex: 1;
  list-style: none;
  text-align: center;
}

.nav-links li {
  display: inline-block;
  margin: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
}

/* Responsive styling */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide navbar links on small screens */
    color: #fff;
  }
}
/* ==================================================================== */

.toggle-icon {
  width: 50px;
  cursor: pointer;
  margin-left: 40px;
}
.logo {
  width: 160px;
  cursor: pointer;
}
.clock {
  display: flex;
  align-items: center;
  background: rgb(65, 169, 255);
  padding: 5px;
  border-radius: 50px;
  color: #fff;
  margin: 2px;
  font-size: 25px;
}
.Welcome {
  display: flex;
  align-items: center;
  background: rgb(65, 169, 255);
  padding: 5px;
  border-radius: 50px;
  font-size: 18px;
  color: #fff;
  max-width: 65px;
  margin: 2px;
}
.dark .navbar {
  background: #0e0b25;
  color: #fff;
}

.dark .clock {
  background: #ced8ff;
  color: #222;
}
/* ============================================================== */

.nav-links-mobile.show {
  transition: 0.2s;
}
/* //=============================================== */

/* Mobile styles */
.nav-links {
  display: none;
}

.hamburger {
  background: none;
  border: none;
  font-size: 2em;
  color: #22203d;
  display: block;
  cursor: pointer;
  position: relative;
  display: flex;
}
.dark .hamburger {
  color: rgb(255, 0, 0);
}

.nav-links-mobile {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #333;
  padding: 1em;
}

.nav-links-mobile li {
  margin: 0.5em 0;
}

.nav-links-mobile a {
  font-size: 1.5em;
  color: white;
}
.list {
  color: #ffffff;
}
.dark .list {
  color: #fff;
}
